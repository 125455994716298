const $ = require('jquery');

const ImageArticle = {
  
  init: function(element) {
    $(element).each(function () {
      var $container = $(this).find('img').parent(),
          imgUrl = $container.find('img').prop('src');
      if (imgUrl) {
        $container
          .css('backgroundImage', 'url(' + imgUrl + ')')
          .addClass('compat-object-fit');
      }  
    });
  }
};

module.exports = ImageArticle;