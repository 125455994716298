const accordionItems = document.querySelectorAll(
  "[data-js-target='accordion-item']",
);

accordionItems.forEach((item) => {
  const accordionItemHeader = item.querySelector("#accordion-item-header");
  const accordionItemButton = accordionItemHeader.firstElementChild;
  const accordionItemImage = item.querySelector("#accordion-item-image");
  const accordionItemBody = item.querySelector("#accordion-item-body");

  let isBodyVisible = false;

  const handleClickButton = () => {
    const toggleStyleClass = (element, className) => {
      element.classList.toggle(className);
    };

    isBodyVisible = !isBodyVisible;
    // Body open
    if (isBodyVisible) {
      toggleStyleClass(
        accordionItemHeader,
        "accordion-item__header--is-active",
      );
      toggleStyleClass(
        accordionItemImage,
        "accordion-item__header__imagebox__image--is-active",
      );
      accordionItemBody.style.height = `${accordionItemBody.scrollHeight}px`;
      // Body closed
    } else {
      toggleStyleClass(
        accordionItemHeader,
        "accordion-item__header--is-active",
      );
      toggleStyleClass(
        accordionItemImage,
        "accordion-item__header__imagebox__image--is-active",
      );
      accordionItemBody.style.height = "0";
    }
  };

  accordionItemButton.addEventListener("click", handleClickButton);
});
