const $ = require('jquery');

const ImageZoom = {

  init: function() {
    $('.image-bg-left-mask').on('click', function() {
	  $(this).parent().toggleClass('active-left');
	});

	$('.image-bg-right-mask').on('click', function() {
	  $(this).parent().toggleClass('active-right');
	});

	$('.btn-close', '.active-left').on('click', function() { 
	    $(this).find('.wrapper').toggleClass('active-left'); 
	});

	$('.btn-close', '.active-right').on('click', function() { 
	   $(this).find('.wrapper').toggleClass('active-right'); 
	});
  }
};

module.exports = ImageZoom;