import Shuffle from "shufflejs"

class Filter {
  filters = {}

  constructor() {
    const element = document.querySelector(
      ".teaser-grid-list-wrapper .teaser-grid-list"
    )

    if (!element) return

    this.filterList = Array.from(document.querySelectorAll("input[data-group]"))
    this.shuffle = new Shuffle(element, {
      itemSelector: ".cell",
      easing: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
    })
    this.resetButton = document.querySelector("[data-filter-reset]")

    this.initFilterList()

    this.hasActiveFilters()

    this._onGroupChanges = this.handleGroupChange.bind(this)

    this.filterList?.forEach((input) => {
      input.addEventListener("change", () => this._onGroupChanges(input))
    })

    this.resetButton?.addEventListener("click", () => {
      this.shuffle.filter(Shuffle.ALL_ITEMS)
      this.clearAllCheckboxes()
      sessionStorage.removeItem("activeFilters")
    })

    this.session = sessionStorage.getItem("activeFilters")

    this.filters = this.session ? JSON.parse(this.session) : {}

    if (this.hasActiveFilters()) {
      this.filterList?.forEach((input) => {
        const dataset = input.dataset[`filterGroup-${input.dataset.group}`]

        Object.values(this.filters).map((value) => {
          if (value.includes(dataset)) {
            input.checked = true
            this.handleGroupChange(input)
            return
          }
        })
      })
    }
  }

  initFilterList() {
    return new Set(this.filterList.map((input) => input.dataset.group)).forEach(
      (filter) => {
        this.filters[filter] = []
      }
    )
  }

  handleGroupChange(group) {
    this.filters[group.dataset.group] = this.filterList
      .filter(
        (input) => input.checked && input.dataset.group === group.dataset.group
      )
      .map((input) => input.dataset[`filterGroup-${input.dataset.group}`])

    this.filter(group)
  }

  clearAllCheckboxes() {
    this.filterList.forEach((input) => {
      input.checked = false
    })
  }

  filter(group) {
    if (this.hasActiveFilters()) {
      this.shuffle.filter((element) => this.itemPassesFilters(element, group))
      sessionStorage.setItem("activeFilters", JSON.stringify(this.filters))
    } else {
      this.shuffle.filter(Shuffle.ALL_ITEMS)
      sessionStorage.removeItem("activeFilters")
    }
  }

  hasActiveFilters() {
    return Object.values(this.filters).some((filter) => filter.length > 0)
  }

  matchesGroup(element, group) {
    const elementGroup = element.dataset[`filter-${group}`]

    if (!elementGroup) return false

    return elementGroup
      .split(",")
      .some((item) => this.filters[group].includes(item))
  }

  itemPassesFilters(element) {
    const checks = []
    const runCheck = (check) => check()

    Object.keys(this.filters).map((input) => {
      if (this.filters[input].length > 0) {
        checks.push(this.matchesGroup.bind(this, element, input))
      }
    })

    sessionStorage.setItem("activeFilters", JSON.stringify(this.filters))

    return checks.every(runCheck)
  }
}

new Filter()
