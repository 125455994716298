const $ = require('jquery');
const Slick = require('@ecedi/slick-carousel');
const Buehne = {
  buehneElement: $('.buehne'),

  init: function() {

    if (!isExperienceEditor()){

      if(!App.isTouchDevice) {
        this.buehneElement.on("init", function(slick){
          slick = $(slick.currentTarget);
          setTimeout(function(){
            Buehne.playPauseVideo(slick,"play");
          }, 1000);
          //resizePlayer(iframes, 16/9);
        });

        this.buehneElement.on("beforeChange", function(event, slick) {
          slick = $(slick.$slider);
          Buehne.playPauseVideo(slick,"pause");
        });

        this.buehneElement.on("afterChange", function(event, slick) {
          slick = $(slick.$slider);
          Buehne.playPauseVideo(slick,"play");
        });
      }

      this.buehneElement.slick({
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: true,
          appendArrows: $('.buehne__content'),
          prevArrow: '<button type="button" class="slick-prev"><?xml version="1.0" encoding="UTF-8"?><svg width="62px" height="62px" viewBox="0 0 62 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>nav_right</title><desc>Created with Sketch.</desc><g id="StMang---Website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="St.Mang---Startseite-Desktop" transform="translate(-89.000000, -657.000000)" fill-rule="nonzero"><g id="hero" transform="translate(0.000000, 98.000000)"><g id="Hero"><g id="hero_layer" transform="translate(-10.000000, -195.000000)"><g id="nav_right" transform="translate(99.000000, 754.000000)"><rect id="Rectangle" fill="#1E1E1E" x="0" y="0" width="62" height="62"></rect><g id="Arrow" transform="translate(25.000000, 14.000000)" stroke="#FFFFFF" stroke-width="2"><polyline id="Path" points="13 0 0 16.5 13 33"></polyline></g></g></g></g></g></g></g></svg></button>',
          nextArrow: '<button type="button" class="slick-next"><?xml version="1.0" encoding="UTF-8"?><svg width="62px" height="62px" viewBox="0 0 62 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>nav_left</title><desc>Created with Sketch.</desc><g id="StMang---Website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="St.Mang---Startseite-Desktop" transform="translate(-1529.000000, -657.000000)" fill-rule="nonzero"><g id="hero" transform="translate(0.000000, 98.000000)"><g id="Hero"><g id="hero_layer" transform="translate(-10.000000, -195.000000)"><g id="nav_left" transform="translate(1570.000000, 785.000000) scale(-1, 1) translate(-1570.000000, -785.000000) translate(1539.000000, 754.000000)"><rect id="Rectangle" fill="#1E1E1E" x="0" y="0" width="62" height="62"></rect><g id="Arrow" transform="translate(25.000000, 14.000000)" stroke="#FFFFFF" stroke-width="2"><polyline id="Path" points="13 0 0 16.5 13 33"></polyline></g></g></g></g></g></g></g></svg></button>',          
          dots: true,
          cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)"
      });

      this.buehneElement.find('.slick-prev').on('click', function(){
        Buehne.buehneElement.slick("slickPrev");
      });
      this.buehneElement.find('.slick-next').on('click', function(){
        Buehne.buehneElement.slick("slickNext");
      });

      if(!App.isTouchDevice) {
        // Resize event
        this.resizePlayer(this.buehneElement.find('.video iframe'), 16/9);
        $(window).on("resize", function(){  
          Buehne.resizePlayer(Buehne.buehneElement.find('.video iframe'), 16/9);
        });
      }
    }
  },

  // POST commands to YouTube or Vimeo API
  postMessageToPlayer: function(player, command){
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  },

  // When the slide is changing
  playPauseVideo: function(slick, control){
    var currentSlide, player, video;

    currentSlide = slick.find(".slick-current");
    var slideType = currentSlide.attr("class").split(" ")[1];
    player = currentSlide.find("iframe").get(0);

    switch (control) {
      case "play":
        Buehne.postMessageToPlayer(player, {
          "event": "command",
          "func": "mute"
        });
        Buehne.postMessageToPlayer(player, {
          "event": "command",
          "func": "playVideo"
        });
        if(player !== undefined)
          slick.slick('slickPause');
        break;
      case "pause":
        Buehne.postMessageToPlayer(player, {
          "event": "command",
          "func": "pauseVideo"
        });
        if(player !== undefined)
          slick.slick('slickPlay');
        break;
    }
  },

  // Resize player
   resizePlayer: function(iframes, ratio) {
    if (!iframes[0]) return;
    var playerWidth,
        playerHeight,
        ratio = ratio || 16/9;

    iframes.each(function(){
      var current = $(this);
      var win = current.parent(),
        width = win.width(),
        height = win.height();

      if (width / ratio < height) {
        playerWidth = Math.ceil(height * ratio);
        current.width(playerWidth).height(height).css({
          left: (width - playerWidth) / 2,
           top: 0
          });
      } else {
        playerHeight = Math.ceil(width / ratio);
        current.width(width).height(playerHeight).css({
          left: 0,
          top: (height - playerHeight) / 2
        });
      }
    });
  },

  handleResize: function() {
    // Find all YouTube videos
    var $allVideos = $(".video iframe"),

        // The element that is fluid width
        $fluidEl = $(".video");

    // Figure out and save aspect ratio for each video
    $allVideos.each(function() {

      $(this)
        .data('aspectRatio', 16 / 9)

        // and remove the hard coded width/height
        .removeAttr('height')
        .removeAttr('width');

    });

    // When the window is resized
    $(window).resize(function() {

      var newWidth = $fluidEl.width();

      // Resize all videos according to their own aspect ratio
      $allVideos.each(function() {

        var $el = $(this);
        $el
          .width(newWidth)
          .height(newWidth * $el.data('aspectRatio'));

      });

    // Kick off one resize to fix all videos on page load
    }).resize();
  }
};

module.exports = Buehne;