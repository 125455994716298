const $ = require('jquery');

module.exports = class Filter {
  constructor(filter, list) {
    this.filter = $(filter);
    this.list = $(list);
    
    if(this.filter.length <= 0 || this.list <= 0) {
    	return;
    }

    if(App.isTouchDevice) {
      this.select = this.filter.find('option[data-filter]').parent();
      this.select.on('change', (event) => { this.filterContent(event); });
    } else {
      this.button = this.filter.find('li [data-filter]');
      this.button.on('click', (event) => { this.filterContent(event); });
    }
  }

  filterContent(event) {
  	event.preventDefault();
    let filterButtonClicked;
    
    if(App.isTouchDevice) {
      filterButtonClicked = $(event.target).find('option:selected');
    } else {
      filterButtonClicked = $(event.target);  
    }
    let filterID = filterButtonClicked.data('filter');    
    
    if(filterID != 'all') {
    	this.list.find('[data-filter]').show().not('[data-filter*="' + filterID + '"]').hide();
    } else {
    	this.list.find('[data-filter]').show();
    }

    this.filter.find('.is-active[data-filter]').removeClass('is-active');
    filterButtonClicked.addClass('is-active');
  }
}