const $ = require('jquery');

const CC = {
  ccBanner: $('.cc-banner'),
  ccBannerCloseButton: $('.cc-banner .cc-btn.cc-dismiss'),
  
  init: function() {

    if(this.ccBanner.length > 0 && !this.isSetCCCookie()){
      this.ccBanner.css('display', 'flex');

      this.ccBannerCloseButton.on('click', (event) => { 
        CC.ccBanner.fadeOut('slow'); 
        CC.setCCCookie(); 
      });   
    }
  },

  setCCCookie: function() {
    document.cookie = 'ccBanner=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
  },

  isSetCCCookie: function() {
    if (document.cookie.indexOf('ccBanner=true') > -1) {
        return true;
    } else {
        return false;
    }
  }
};

module.exports = CC;