const $ = require('jquery');

const YoutubeVideo = {

  init: function() {
    
  },

   
};

module.exports = YoutubeVideo;