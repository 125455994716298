﻿const $ = require('jquery')

$(function() {
  function findStores(distance, areaCode, productType, storeFinderId) {
    let url = `/api/sitecore/StoreFinder/Stores?distance=${distance}&areaCode=${areaCode}&productType=${productType}&storeFinderId=${storeFinderId}`
    $.ajax({
      url: url
    }).done(function(data) {
      $('.storefinder-item').remove()
      $('.storefinder-error-message').remove()
      $('.storefinder-noresults-message').remove()
      $('.storefinder-items').append(data)
    })
  }

  function findStoresWithValues(e) {
    e.preventDefault()
    let formValues = $('#storefinder-inputs').serializeArray()
    let areaCode = formValues[0].value
    let productType = formValues[1].value
    let distance = parseInt(formValues[2].value)
    let storeFinderId = formValues[3].value
    findStores(distance, areaCode, productType, storeFinderId)
  }

  $('#storefinder-inputs').on('submit', findStoresWithValues)
  $('#storefinder-search').on('click', findStoresWithValues)
})
