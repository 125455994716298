const $ = require('jquery');

const DualSliding = {

  init: function() {
    var $l = $('.dual_sliding .left')
	var $r = $('.dual_sliding .right')

	$l.mouseenter(function() {
	  $('.dual_sliding__container').addClass('left-is-hovered');
	}).mouseleave(function() {
	  $('.dual_sliding__container').removeClass('left-is-hovered');
	});

	$r.mouseenter(function() {
	  $('.dual_sliding__container').addClass('right-is-hovered');
	}).mouseleave(function() {
	  $('.dual_sliding__container').removeClass('right-is-hovered');
	});
  }
};

module.exports = DualSliding;