const $ = require('jquery');

const SplitScreen = {
  splitScreenElement: $('.splitview'),
  
  init: function() {
    this.splitScreenElement.each(function(){
        var parent = $(this),
            topPanel = $(this).find('.top'),
            handle = parent.find('.handle'),
            skewHack = 0,
            delta = 0;

        // If the parent has .skewed class, set the skewHack var.
        if (parent.hasClass('skewed') != -1) {
            skewHack = 1000;
        }

        parent.on('mousemove taphold', function(event) {
            // Get the delta between the mouse position and center point.
            delta = (event.clientX - window.innerWidth / 2) * 0.5;

            // Move the handle.
            handle.css('left', event.clientX + delta + 'px');

            // Adjust the top panel width.
            topPanel.css('width', event.clientX + skewHack + delta + 'px');
        });
    });
  }
};

module.exports = SplitScreen;