import "./accordion.js";

console.log("rougette overrides");

Array.prototype.forEach.call(
  document.querySelectorAll(".social-image-grid__share"),

  function (button) {
    const wrapper = button.closest(".social-image-grid__content");
    const allWrapper = document.querySelectorAll(".social-image-grid__content");

    button.addEventListener("click", () => {
      if (!wrapper.classList.contains("is-open")) {
        allWrapper.forEach((item) => item.classList.remove("is-open"));
      }
      wrapper.classList.toggle("is-open");
    });
  },
);

Array.prototype.forEach.call(
  document.querySelectorAll(".file-upload__button"),

  function (button) {
    const hiddenInput = button.parentElement.querySelector(
      ".file-upload__input",
    );
    const label = button.parentElement.querySelector(".file-upload__label");
    const defaultLabelText = "Kein Bild ausgewählt.";

    label.textContent = defaultLabelText;
    label.title = defaultLabelText;

    button.addEventListener("click", function () {
      hiddenInput.click();
    });

    hiddenInput.addEventListener("change", function () {
      // const filenameList = Array.prototype.map.call(
      //   hiddenInput.files,

      //   function (file) {
      //     return file.name;
      //   },
      // );

      label.textContent = "Bild erfolgreich ausgewählt.";

      // label.textContent =
      //   filenameList.length > 1
      //     ? `${filenameList.length} Dateien ausgewählt`
      //     : filenameList || defaultLabelText;

      label.title = label.textContent;
    });
  },
);
