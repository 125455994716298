const $ = require('jquery');
const Menu = {
  mainMenuButon: $('.js-mainmenu'),
  mainMenu: $('#header'),

  init: function() {
    this.mainMenuButon.on('click touchstart', (event) => { this.handleMenuClick(event); })
    this.initMobileMenu();
  },

  handleMenuClick: function(event) {
    event.preventDefault();
    this.mainMenu.toggleClass('is-open');
    this.mainMenu.find('#icon-menu-mobile').toggleClass('open');
  },

  initMobileMenu: function() {
    this.mainMenu.find('.submenu').each(function(){
      var parent = $(this).parent();
      parent.addClass('has-submenu');
      parent.prepend('<span class="submenu-toggler"><span class="closed">+</span><span class="open">-</span></span>');
      parent.find('.submenu-toggler').on('touchstart', (event) => { $(this).parent().toggleClass('open-submenu') })
    });
  },
};

module.exports = Menu;