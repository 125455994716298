const $ = require('jquery');

const Carousel = {

  init: function() {
    var $num = $('.ui-card').length;
    var $even = $num / 2;
    var $odd = ($num + 1) / 2;

    if ($num % 2 == 0) {
      $('.carousel .contain').addClass('correct-position');
      $('.ui-card:nth-child(' + $even + ')').addClass('active');
      $('.ui-card:nth-child(' + $even + ')').prev().addClass('prev');
      $('.ui-card:nth-child(' + $even + ')').next().addClass('next');
    } else {
      $('.ui-card:nth-child(' + $odd + ')').addClass('active');
      $('.ui-card:nth-child(' + $odd + ')').prev().addClass('prev');
      $('.ui-card:nth-child(' + $odd + ')').next().addClass('next');
    }

    $('.ui-card').click(function() {
      var $slide = $('.active').width();
      
      if ($(this).hasClass('next')) {
        $('.contain').stop(false, true).animate({left: '-=' + ($slide)});
      } else if ($(this).hasClass('prev')) {
        $('.contain').stop(false, true).animate({left: '+=' + ($slide)});
      }
      
      $(this).removeClass('prev next');
      $(this).siblings().removeClass('prev active next');
      
      $(this).addClass('active');
      $(this).prev().addClass('prev');
      $(this).next().addClass('next');
    });


    // Keyboard nav
    $('html body').keydown(function(e) {
      if (e.keyCode == 37) { // left
        $('.active').prev().trigger('click');
      }
      else if (e.keyCode == 39) { // right
        $('.active').next().trigger('click');
      }
    });
  }
};

module.exports = Carousel;